import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Animation that represents a loading process.
 */
@Component({
  selector: 'ush-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent {
  /**
   * Aplied to background style prop.
   */
  @Input() skWidth?: string = '100%';
  @Input() skHeight?: string = '100%';
}
