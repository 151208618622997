import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  ViewContainerRef,
  afterNextRender
} from '@angular/core';
import { SkeletonComponent } from '../skeleton/skeleton.component';

@Directive({
  selector: '[addSkeleton]'
})
export class AddSkeletonDirective {
  private siblingElement: HTMLElement;
  private componentRef: ComponentRef<SkeletonComponent>;
  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2
  ) {
    afterNextRender(() => {
      this.addSkeletonComponent();
    });
  }

  @HostListener('load')
  onHostLoad() {
    // Remove the skeleton component from the DOM tree
    this.removeSkeletonComponent();
  }

  private addSkeletonComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SkeletonComponent);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);

    // suscribir a (load) del elemento host

    // when load is completed delete this p-skeleton and delete listener

    this.siblingElement = this.componentRef.location.nativeElement;
    const parentNode = this.viewContainerRef.element.nativeElement.parentNode;

    // Add position: relative to the parent element
    this.renderer.setStyle(parentNode, 'position', 'relative');

    // Check if the display property of parentNode is different from "block" or "flex"
    const parentNodeDisplay = window.getComputedStyle(parentNode).display;
    if (parentNodeDisplay !== 'block' && parentNodeDisplay !== 'flex') {
      // Perform your action here
      // Adding display block to parent if flex or block not found
      this.renderer.setStyle(parentNode, 'display', 'block');
    }

    parentNode.insertBefore(this.siblingElement, this.viewContainerRef.element.nativeElement.nextSibling);
  }

  private removeSkeletonComponent(): void {
    if (this.siblingElement && this.siblingElement.parentNode) {
      this.siblingElement.parentNode.removeChild(this.siblingElement);
    }

    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
