import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '../image.service';
import { ImageParamsWrapper } from '@usheru-hff/shared/domain';

@Pipe({
  name: 'img'
})
/**
 * gets a movie object with a Params object and returns an img URL that will,
 * query to the AWS imageHandling API, wich
 * The Params object should have atributes such as:
 * params = {
 *   height: 200,
 *   width: 300,
 *   fit: 'cover'
 *   type: 'portrait'  //mandatory
 * }
 * If only height or width is provided it will return a scaled image with 'outside' fit
 * If only type param is provided it will return image with original size
 *
 * for extra possible parameters check the oficial documentation at:
 * https://aws.amazon.com/solutions/implementations/serverless-image-handler/
 *
 *
 *
 * @export
 * @class ImgPipe
 * @implements {PipeTransform}
 */
export class ImgPipe implements PipeTransform {
  constructor(private imageService: ImageService) {}

  transform(value: string, params?: ImageParamsWrapper): string {
    return this.imageService.computeUrl(value, params);
  }
}
