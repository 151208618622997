import { CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

import { Environment, ENVIRONMENT } from '@environment';
import { ChannelService } from '@usheru-hff/sdk/channel';

import { myCustomLoader } from './custom-loader';
import { ImageService } from './image.service';
import { AddSkeletonDirective } from './infrastructure/add-skeleton.directive';
import { ImgPipe } from './infrastructure/img.pipe';
import { SkeletonComponent } from './skeleton/skeleton.component';

@NgModule({
  imports: [CommonModule, NgOptimizedImage, SkeletonModule],
  declarations: [ImgPipe, AddSkeletonDirective, SkeletonComponent],
  exports: [ImgPipe, NgOptimizedImage, AddSkeletonDirective],
  providers: [
    ImageService,
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [480, 640, 750, 828, 1080, 1200, 1920]
      }
    },
    {
      provide: IMAGE_LOADER,
      useFactory: (channelService: ChannelService, enviroment: Environment) => (config: ImageLoaderConfig) =>
        myCustomLoader(config, channelService.channel, enviroment),
      deps: [ChannelService, ENVIRONMENT]
    }
  ]
})
export class SharedUtilsImageModule {}
